<template>
    <v-container fluid >
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-container class="py-1" fluid v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) " >
        <v-row no-gutters>
          <v-col align="right">
            <CreateProcessDialog/>
          </v-col>
        </v-row>
    </v-container>
    <!-- <v-row no-gutters v-if="$store.state.processes.length==0">
      <v-col align="center" style="padding: 20px;">
        <v-icon large :color="$store.getters.getColorPalette().accentCode">mdi-hail</v-icon>
        <h3 :style="{color:$store.getters.getColorPalette().accentCode}" >No Processes Configured</h3>
      </v-col>
    </v-row> -->
    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
    <v-row no-gutters v-else>
      <v-col>    
        <v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider>     
          <v-tabs
            show-arrows
            :dark="$store.getters.getColorPalette().isDark"
            :background-color="$store.getters.getColorPalette().backgroundColorCode"
            v-model="tab"
          >
            <v-tabs-slider :color="$store.getters.getColorPalette().accentCode"></v-tabs-slider>
            <router-link v-for="process in $store.state.processes" :key="process.process_id" :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="`/processes/${process.process_id}`">
              <v-tab>{{process.name}}</v-tab>
            </router-link>
          </v-tabs>
          <v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider>
          <v-tabs-items v-model="tab">
            <v-tab-item
            v-for="process in $store.state.processes"
            :key="process.process_id"
            :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}"
            >
            <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider>
            <v-row class="mt-1" :color="'red'" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
              <v-col align="right">
                <v-btn class="mx-1" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="showAdd=!showAdd"> {{ $store.getters.getTextMap().add_machine }}<v-icon :color="$store.getters.getColorPalette().btnborderColorCode" @click="showAdd=!showAdd">mdi-plus</v-icon></v-btn>
                <!-- <DeleteConfirmation v-on:confirm="deleteProcess(process)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">
                  <v-btn class="mx-1" :color="$store.getters.getColorPalette().accentCode" small text outlined>Delete Process<v-icon :color="$store.getters.getColorPalette().alertColor" >mdi-delete</v-icon></v-btn>
                </DeleteConfirmation> -->
              </v-col>
            </v-row>
            <v-row no-gutters v-if="showAdd">
              <v-col>
                <AddMachineToProcess :process="process" v-on:close="showAdd=false" />
              </v-col>
            </v-row>
            <!-- <MachinePage :process_id="process.process_id" ></MachinePage> -->
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>   
    <router-view></router-view>    
 </v-container>  
</template>
<script>
import CreateProcessDialog from '@/components/modals/CreateProcessDialog'
// import MachinePage from '@/pages/MachinePage'
import AddMachineToProcess from '@/components/crud_components/AddMachineToProcess.vue';
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
// import DeleteConfirmation from '@/components/DeleteConfirmation'

export default {
 name:'EntureProcesses',
 components:{
    CreateProcessDialog,
    // MachinePage,
    AddMachineToProcess,
    InfoAlert,
    // DeleteConfirmation
},
created() {
    // Redirect to the first workspace ID
    if (this.$store.state.processes.length > 0) {
      const firstProcessId = this.$store.state.processes[0].process_id;
      this.$router.push(`/processes/${firstProcessId}`);
    }
  },
 mounted() {
   document.title='Enture Processes'
        if (!this.$store.state.loggedIn) {
     this.$router.push("/");
   }
   },
 data(){
   return {    
    showDialog:false,
     tab:null,
     showAdd:false,
     loading:false,
     showDismissibleAlert:false,
     info:''
   }
 },
 methods:{
   deleteProcess(process){
    this.loading = true;
      axios.post(this.$store.state.api + "deleteProcess", process,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    
                    
                    //console.log(response.data);
                    
                    this.$store.dispatch('refreshProcesses')
                //this.loading = false;
                    
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
   },
     cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
 }
}
</script>