<template>
    <v-container fluid :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}" >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="pa-3 mt-2">
            <v-row>
                <v-col>
                    <v-card-title>{{ $store.getters.getTextMap().add_machine_to_process }}</v-card-title>
                </v-col>
            </v-row>
            <v-form ref="usageForm" v-model="valid">
                <v-row>        
                    <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-select
                            v-if="!loading"
                            v-model="workspace"
                            :items="workspaceOptions"
                            :label="$store.getters.getTextMap().workspace "
                            outlined   
                            dense       
                            item-text="label"
                            item-value="value"
                            required
                            :dark="$store.getters.getColorPalette().isDark"
                            :rules="objectRules"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-select
                            v-if="!loading"
                            v-model="form.machines"
                            :items="machineOptions"
                            :label="$store.getters.getTextMap().machine"
                            outlined
                            dense
                            item-text="label"
                            item-value="value"
                            required
                            multiple
                            :dark="$store.getters.getColorPalette().isDark"
                            :rules="objectRules"
                        ></v-select>
                    </v-col>
                    <v-col v-if="!loading">
                        <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" text small outlined @click="onSubmit">{{$store.getters.getTextMap().add}}</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
export default {
    name:'AddMachineToProcess',
    props:{
        process:{
            type:Object,
            required:true
        }
    },
    components:{
        InfoAlert
    },
    data(){
        return {
            loading:false,
            valid:false,
            showDismissibleAlert:false,
            info:'',
            workspace:null,
            form:{},
            machineOptions:[],
            objectRules:[v => !!v || 'Required',],
        }
    },
    computed:{
        workspaceOptions(){
            let op=[]
            let g=this.$store.state.workspaces
            for(let i of g){
                op.push( {value:i.workspace_id, label: i.name })
            }
          return op
        },
    },
    methods:{
        getMachineList(){
            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace
                }
                this.loading = true;
                axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                if (response.data.status == "success") {
                    let machines=response.data.machines
                    for(let i of machines){
                        if(i && i.name){
                            op.push({value:i.machine_id, label: i.name })
                        }else{
                            op.push({value:i.machine_id, label: i.machine_id })
                        }
                    }
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false;
                })
                .catch(error => {
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
            }
            this.machineOptions=op
        },
        onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid && this.process && this.process.process_id){
                this.loading=true
                this.form.process_id=this.process.process_id
                axios.post(this.$store.state.api+'attachMachinesToProcess',this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.loading=false
                        this.$emit('success')
                        this.$emit('close')
                     }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                }})
                .catch(err=>{console.error(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
                });
            }
        },
        onReset(){
            this.$refs.usageForm.reset()
            this.loading=false
        }
    },
    watch:{
        workspace(){
            this.getMachineList()
        }
    }
}
</script>