<template>
    <v-container>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-form
            ref="viewForm"
            v-model="valid"    
          >
        <v-row >
            <v-col>
            <v-text-field
                        :loading="loading"
                        v-model="form.name"
                        :counter="25"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().name"
                        required
                    ></v-text-field>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
            <v-col>
            <v-text-field
                        :loading="loading"
                        v-model="form.msg"
                        :counter="55"
                        :rules="msgRules"
                        :label="$store.getters.getTextMap().description"
                        required
                    ></v-text-field>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
        </v-row>
        <v-row>
            <v-col align="center">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                        :color="$store.getters.getColorPalette().submitbtnColor"
                     
                        @click="onSubmit"
                    >
                    {{ $store.getters.getTextMap().submit  }}
                    </v-btn>
                </v-col>
                <v-col align="center">
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                      :color="$store.getters.getColorPalette().cancelbtnColor"
                      :dark="$store.getters.getColorPalette().isDark" 
                        @click="close"
                    >
                    {{  $store.getters.getTextMap().cancel }}
                    </v-btn>
                </v-col>
        </v-row>
    </v-form>
    </v-container>
            
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
export default {
    name:'CreateProcess',
    components:{
        InfoAlert
    },
    data(){
        return {
            loading:false,
            showDismissibleAlert:false,
            info:'',
            valid:false,
            form:{
                name:null,
                view_type:'tableView',
                data:{}
            },
            nameRules: [
                v => !!v || this.$store.getters.getTextMap().email_is_required,
                v => /\S+/.test(v) ||this.$store.getters.getTextMap().email_is_required,
                v => (v && v.length <= 25 && v.length > 0) || this.$store.getters.getTextMap().name_must_be_less_than_25_characters,
            ],
            msgRules: [
                v => !!v || this.$store.getters.getTextMap().description_is_required,
                v => (v && v.length <= 55 && v.length > 0) || this.$store.getters.getTextMap().description_must_be_less_than_55_characters,
            ],
            selectRules:[
                v=> (v!=null) ||  this.$store.getters.getTextMap().required
            ],
        }
    },
    computed:{
        
    },
    methods:{
        onSubmit(){
            this.$refs.viewForm.validate()
            if(this.valid){
                 this.loading=true
                let d=Object.assign({}, this.form)
                //console.log(d)
                d['data']={headers:[],params:[]}
                axios.post(this.$store.state.api+'createProcess',d,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.$store.dispatch('refreshProcesses')
                            this.$refs.viewForm.reset()
                            this.close()
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        close(){
            this.$emit('close')
        }
    }
}
</script>